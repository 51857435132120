import Form from "@/components/form/Form.vue";
import getServices from "@/api/services";

export default {
	components: {Form},
	metaInfo: {
		title: 'Services – ImmerseFX'
	},
	computed: {
		servicesList() {
			if (this.state.services.data) {
				return this.state.services.data
			}
			return []
		},
	},
	created() {
		getServices.getAll(this)
	}
}
